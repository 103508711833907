import mixitup from 'mixitup';

function init() {

  const mixElem = document.querySelector('.b-filterable-references .mixitup-container');

  if (mixElem) {

    const selectedCat = mixElem.getAttribute('data-selected-cat');

    let controlsForm = document.forms["controls-form"];
    controlsForm.onchange = evt => {

      if (evt.target.value != 'kaikki') {
        window.history.pushState({}, 'Change category', '?cat=' + evt.target.value );
      }
      else {
        const url = new URL(window.location.href);

        if (url.searchParams.has('cat')) {
          url.searchParams.delete('cat');
          history.pushState({}, "Change category", url);
        }
      }
    }

    var mixer = mixitup( mixElem, {
      load: {
        filter: selectedCat
      },
      animation: {
        clampHeight: false,
      }
    });

  }

}

export default {
  init,
};
